import React, { useState, useEffect } from 'react'
import { Button, Form, Divider, Typography, Modal, Spin } from 'antd'
import FormField from './FormField'
import FormTable from './form-table/FormTable'

import {
  nameValidation,
  emailValidation,
  requestStoreValidation,
  deliveredToStoreValidation,
  staffNameValidation,
  defaultValidation
} from './util/validation'
import stores from './assets/stores.json'
import { getResubmissionById } from '../http'
import { getParams } from './util'

const { Title, Text } = Typography

const WebForm = props => {
  const { setFormState, formState } = props
  const [form] = Form.useForm()

  const formId = getParams()
  const [isSpinning, setIsSpinning] = useState(false)

  useEffect(() => {
    if (formId && !formState?.edit) {
      ;(async () => {
        setIsSpinning(true)
        await getResubmissionById(formId)
          .then(res => {
            if (res.status === 'PENDING') {
              Modal.info({
                title: 'Note',
                content:
                  'The form you are trying to access is currently being processed.'
              })
              setIsSpinning(false)
              setTimeout(() => {
                window.location.href = '/'
              }, 5000)
            }
            if (res.status === 'COMPLETE') {
              Modal.info({
                title: 'Note',
                content:
                  'The form you are trying to access has already been completed.'
              })
              setIsSpinning(false)
              setTimeout(() => {
                window.location.href = '/'
              }, 5000)
            }
            const webForm = res.form
            const orderErrorIds = Object.keys(res.orderError)
            const orders = webForm?.orders.map(order => {
              const errorOrder = orderErrorIds.find(
                errorId => errorId === order.orderItemId
              )
              if (!!errorOrder) {
                return {
                  ...order,
                  error: 'Business Exception',
                  addNew: false
                }
              }
              return { ...order, addNew: false }
            })
            form.setFieldsValue({
              ...webForm,
              orders
            })

            setFormState(state => ({
              ...state,
              values: webForm
            }))
            setIsSpinning(false)
          })
          .catch(err => {
            Modal.warning({
              title: 'Error',
              content:
                'An error occurred while fetching the resubmission data. Please copy the link and try again later.'
            })
          })
      })()
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue(formState.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitted])

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 12 },
      md: { span: 12 }
    },
    wrapperCol: {
      xs: { span: 0 },
      sm: { span: 12 },
      md: { span: 12 }
    }
  }

  const onFinish = values => {
    values.orders.map(order => {
      if (order.size === 'na') order.size = 'NA'
      return (order.productCode = order.productCode.toUpperCase())
    })

    console.log('Received values of form: ', values)
    setFormState(state => ({
      ...state,
      submitted: true,
      submitting: false,
      values
    }))
  }

  const [deliveryValue, setDeliveryValue] = useState(
    formState.values.delivery !== ''
      ? formState.values.delivery
      : 'The customer'
  )
  const deliveryOptions = [
    'The requesting store',
    'The customer',
    'Another store'
  ]
  useEffect(() => {
    setDeliveryValue(formState.values.delivery || 'The customer')
  }, [formState.values.delivery])

  return (
    <Spin size='large' tip='Loading...' spinning={isSpinning}>
      <Form
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        initialValues={formState.values}
        scrollToFirstError
      >
        <FormField
          name='customerName'
          label='Customer Name'
          validation={nameValidation}
          disabled={formId}
        />

        <FormField
          name='customerEmail'
          label='Customer Email'
          validation={emailValidation}
          disabled={formId}
        />

        <FormField
          name='phone'
          prefixName='phonePrefix'
          phonePrefix={formState.values.phonePrefix}
          numberName='phoneNumber'
          label='Customer Phone'
          inputType='phone'
          disabled={formId}
        />

        <FormField
          name='customerRef'
          label="Customer's Reference"
          disabled={formId}
        />

        <FormField
          name='delivery'
          label='Where will this order be delivered?'
          inputType='radio'
          radioValue={deliveryValue}
          setRadioValue={setDeliveryValue}
          values={deliveryOptions}
          disabled={formId}
        />

        {deliveryValue === 'The customer' && (
          <>
            <FormField
              name='address'
              label='Customer Delivery Address'
              inputType='address'
              validation={defaultValidation}
              disabled={formId}
            />
          </>
        )}
        {deliveryValue === 'Another store' && (
          <>
            <FormField
              name='storeToDeliver'
              label='Deliver to Store'
              inputType='dropdown'
              validation={deliveredToStoreValidation}
              disabled={formId}
              values={stores}
            />
          </>
        )}

        <Divider></Divider>

        <Title level={4}>Product Order Info</Title>
        <FormField
          name='requestingStore'
          label='Requested by Store'
          inputType='dropdown'
          validation={requestStoreValidation}
          values={stores}
          disabled={!!getParams()}
        />

        <FormField
          name='staffName'
          label='Staff Members Name'
          validation={staffNameValidation}
          disabled={!!getParams()}
        />

        <Text>
          An alterations form must be uploaded for any order requiring
          alterations.{' '}
          {!!getParams() && (
            <Text>
              Please delete products and resubmit the form if this order needs
              to be cancelled.
            </Text>
          )}
        </Text>

        <Form.List name='orders'>
          {(orders, { add, remove }) => {
            return (
              <>
                <FormTable
                  form={form}
                  orders={orders}
                  add={add}
                  remove={remove}
                ></FormTable>
              </>
            )
          }}
        </Form.List>

        <Form.Item wrapperCol={{ span: 4, offset: 10 }}>
          <Button type='primary' htmlType='submit'>
            Proceed to review
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

export default WebForm
