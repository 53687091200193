import axios from 'axios'

const baseUrl =
  process.env.REACT_APP_ENV === 'development'
    ? process.env.REACT_APP_REQUEST_DEV_URL
    : process.env.REACT_APP_REQUEST_URL

export const getResubmissionById = async id => {
  return await axios
    .get(`${baseUrl}/api/get-resubmission-by-id/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.data)
}

export const getAuthRole = async () => {
  return await axios
    .get(`${baseUrl}/.auth/me`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.data)
}
