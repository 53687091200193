import { useEffect, useMemo, useState } from 'react'
import { Input, Form, Col, InputNumber } from 'antd'
// import UploadField from '../form-input-types/UploadField'
import AddAlterationsFormModal from '../form-input-types/AddAlterationsFormModal'
import { isObjectEmpty, isGantonTemplate } from '../util'

const TableItem = ({ dataIndex, index, validation, form }) => {
  const { orders } = form.getFieldsValue()
  const [productCode, setProductCode] = useState('')
  const requestingStore = Form.useWatch('requestingStore', form)

  const orderItem = (orders && orders[index]) || {}
  const hasOrderError = orders && !!orderItem?.error

  const handleProductInput = e => {
    setProductCode(e.target.value.toUpperCase())
    if (orders !== undefined) {
      form.setFieldsValue({})
    }
  }

  const templateQty = {
    // Notes: later will change by customer's requirement, in case of the customer have different template,
    // Notes:  better later store the data in the database
    cambridge: '4',
    ganton: '9'
  }
  const qtyConfig = useMemo(() => {
    const isGanton = isGantonTemplate(requestingStore)
    const max = isGanton ? templateQty.ganton : templateQty.cambridge

    if (!isGanton) {
      const isOverNumberValue =
        orders?.filter(o => o.quantity > templateQty.cambridge).length > 0
      // Notes: this part of code is for the case when the user changes the template
      // from Ganton to Cambridge, then if the max number of quantity is more
      // than the templateQty.cambridge, then the quantity will be set to the templateQty.cambridge
      if (isOverNumberValue) {
        const newOrders = orders.map(o => {
          if (o.quantity > templateQty.cambridge) {
            return { ...o, quantity: templateQty.cambridge }
          }
          return o
        })

        form.setFieldsValue({ orders: newOrders })
      }
    }

    return max
  }, [requestingStore, isGantonTemplate])

  switch (dataIndex) {
    case 'quantity':
      return (
        <Form.Item
          wrapperCol={{ span: 0 }}
          name={[index, dataIndex]}
          rules={validation}
          style={{ padding: '24px 10px 0 10px', width: '80px' }}
        >
          <InputNumber
            min={1}
            max={qtyConfig}
            style={{ width: '100%', borderColor: hasOrderError && '#ff4d4f' }}
          />
        </Form.Item>
      )
    case 'alterationFields':
      return (
        <Col align='center'>
          {orderItem?.addNew ? (
            <AddAlterationsFormModal
              index={index}
              dataIndex={dataIndex}
              form={form}
              key={index}
            />
          ) : isObjectEmpty(orderItem?.alterationFields || {}) ? (
            'N/A'
          ) : (
            'Attached'
          )}
          {/* <UploadField
            index={index}
            dataIndex={dataIndex}
            validation={validation}
            form={form}
          /> */}
        </Col>
      )
    case 'productCode':
      return (
        <Form.Item
          wrapperCol={{ span: 0 }}
          name={[index, dataIndex]}
          rules={validation}
          style={{ padding: '24px 10px 0 10px', width: '100%' }}
        >
          <Input
            type='text'
            value={productCode}
            onChange={e => handleProductInput(e)}
            style={{
              textTransform: 'uppercase',
              borderColor: hasOrderError && '#ff4d4f'
            }}
            autoComplete='off'
          />
        </Form.Item>
      )
    case 'fabricCode':
      return (
        <Form.Item
          wrapperCol={{ span: 0 }}
          name={[index, dataIndex]}
          rules={validation}
          style={{
            padding: '24px 10px 0 10px',
            width: '100%'
          }}
        >
          <Input
            autoComplete='off'
            style={{ borderColor: hasOrderError && '#ff4d4f' }}
          />
        </Form.Item>
      )
    default:
      return (
        <Form.Item
          wrapperCol={{ span: 0 }}
          name={[index, dataIndex]}
          rules={validation}
          style={{
            padding: '24px 10px 0 10px',
            width: '100%'
          }}
        >
          <Input
            autoComplete='off'
            style={{ borderColor: hasOrderError && '#ff4d4f' }}
          />
        </Form.Item>
      )
  }
}

export default TableItem
