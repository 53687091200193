import React from 'react'
import { Row, Col, Typography, Button, Card } from 'antd'
import logo from './components/assets/cambridge-clothing-ltd.png'
import { useLocation } from 'react-router-dom'

const { Title } = Typography

const Login = () => {
  const url = window.location.origin

  return (
    <div
      className='container'
      style={{ height: '100vh', background: '#071C2C' }}
    >
      <Row justify='center' align='middle' style={{ height: '100%' }}>
        <Card style={{ background: '#071C2C', border: 'none' }}>
          <Col span={24} align='center'>
            <img
              src={logo}
              alt='logo'
              style={{ height: '82px', width: 'auto' }}
            />
            <Title level={4} style={{ color: 'white', marginBottom: '24px' }}>
              Cambridge Clothing Order Form
            </Title>
          </Col>
          <Col span={24} align='center'>
            <Button href={`/.auth/login/aad?post_login_redirect_uri=${url}`}>
              Login
            </Button>
          </Col>
        </Card>
      </Row>
    </div>
  )
}

export default Login
