import { useEffect, useState } from 'react'
import { getAuthRole } from '../../http'

const useUserRole = () => {
  const [userRole, setUserRole] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Fetch user roles from the server
    ;(async () => {
      try {
        const data = await getAuthRole()
        const roles = data.clientPrincipal?.userRoles || []
        setUserRole(roles)
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching user roles:', error)
        setIsLoading(false)
      }
    })()
  }, [])

  return { userRole, isLoading }
}

export default useUserRole
