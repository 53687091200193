import React from 'react'
import { Typography, Button, Row, Col } from 'antd'

const { Title, Text } = Typography

const Acceptance = props => {
  const { formState, handleReset, handlePostFailed } = props

  return (
    <Row justify='center'>
      {!formState.postingFailed ? (
        <>
          <Col span={24} align='center'>
            <Title level={4} type='success'>
              We are processing your order.
            </Title>
          </Col>

          <Col span={16} align='center' style={{ marginTop: '1rem' }}>
            <Text>
              Email cambridgesales@cambridgeclothing.com to cancel or update
              your order referencing your customer order number provided below.
            </Text>
          </Col>

          <Col span={24} align='center'>
            <Title level={3}>
              Customer Reference Number:{' '}
              {formState.values.customerRef.length
                ? `${formState.values.customerRef}`
                : 'Not Provided'}
            </Title>
          </Col>

          <Col span={24} align='center' style={{ margin: '2rem 0' }}>
            <Button type='primary' size='large' onClick={() => handleReset()}>
              Create new Order
            </Button>
          </Col>
        </>
      ) : (
        <>
          <Col span={24} align='center'>
            <Title level={4} type='danger'>
              Oops, something went wrong.
            </Title>
          </Col>

          <Col span={16} align='center' style={{ marginTop: '1rem' }}>
            <Text>Please go back to your order and resubmit.</Text>
          </Col>

          <Col span={24} align='center' style={{ margin: '2rem 0' }}>
            <Button
              type='primary'
              size='large'
              onClick={() => handlePostFailed()}
            >
              Go back to order
            </Button>
          </Col>
        </>
      )}
    </Row>
  )
}

export default Acceptance
