import React from 'react'
import {
  Table,
  Divider,
  Typography,
  Descriptions,
  Button,
  Space,
  Row,
  Col
} from 'antd'

import stores from './assets/stores.json'
import { isObjectEmpty } from './util'

const { Title, Text } = Typography
const { Item } = Descriptions

const FormReview = props => {
  const { formState, handleEdit, handlePost } = props

  const form = formState.values

  const orderHeader = [
    {
      title: 'Product Code',
      dataIndex: 'productCode',
      align: 'center'
    },
    {
      title: 'Size',
      dataIndex: 'size',
      align: 'center'
    },
    {
      title: 'Colour',
      dataIndex: 'colour',
      align: 'center'
    },
    {
      title: 'Fabric Code',
      dataIndex: 'fabricCode',
      align: 'center'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      align: 'center'
    },
    {
      title: 'Alterations',
      dataIndex: 'alterationFields',
      align: 'center'
    }
  ]

  let orderData = []
  form.orders.map((order, index) => {
    return orderData.push({
      key: index,
      orderItemId: order.orderItemId,
      productCode: order.productCode,
      size: order.size,
      colour: order.colour,
      fabricCode: order.fabricCode,
      quantity: order.quantity,
      alterationFields: isObjectEmpty(order.alterationFields || {})
        ? 'No'
        : 'Yes'
    })
  })

  const findStoreName = storeCode => {
    const store = stores.filter(store => storeCode === store.code)

    return store[0].store
  }

  return (
    <>
      <Col span={24}>
        <Descriptions
          title='Customer Info'
          bordered
          labelStyle={{ fontWeight: 'bold', width: '50%' }}
        >
          <Item label='Customer Name' span={24}>
            {form.customerName}
          </Item>
          <Item label='Customer Email' span={24}>
            {form.customerEmail}
          </Item>
          <Item label='Customer Phone' span={24}>
            {`${form.phonePrefix} ${form.phoneNumber}`}
          </Item>
          <Item label="Customer's Reference" span={24}>
            {form.customerRef}
          </Item>
          <Item label='Where will this order be delivered?' span={24}>
            {form.delivery}
          </Item>
          {form.delivery === 'The customer' && (
            <>
              <Item label='Customer Street Address' span={24}>
                {form.address?.street}
              </Item>
              <Item label='Street Address Line 2' span={24}>
                {form.address?.street2}
              </Item>
              <Item label='City' span={24}>
                {form.address?.city}
              </Item>
              <Item label='State/Province' span={24}>
                {form.address?.state}
              </Item>
              <Item label='Postal/Zip Code' span={24}>
                {form.address?.zip}
              </Item>
              <Item label='Country' span={24}>
                {form.address?.country}
              </Item>
            </>
          )}
          {form.delivery === 'Another store' && (
            <>
              <Item label='Deliver to Store' span={24}>
                {`${findStoreName(form.storeToDeliver)} / ${
                  form.storeToDeliver
                }`}
              </Item>
            </>
          )}
        </Descriptions>
      </Col>

      <Divider></Divider>

      <Row gutter={[0, 30]}>
        <Col span={24}>
          <Title level={5} style={{ margin: '0' }}>
            Product Order Info
          </Title>
        </Col>

        <Col span={24}>
          <Table
            bordered
            columns={orderHeader}
            dataSource={orderData}
            pagination={false}
            size='middle'
          />
        </Col>

        <Col span={24}>
          <Descriptions
            bordered
            labelStyle={{ fontWeight: 'bold', width: '50%' }}
          >
            <Item label='Requested by Store' span={6}>
              {`${findStoreName(form.requestingStore)} / ${
                form.requestingStore
              }`}
            </Item>
            <Item label='Staff member name' span={6}>
              {form.staffName}
            </Item>
          </Descriptions>
        </Col>
        <Col span={24}>
          <Row justify='center'>
            <Space align='center' size='large'>
              <Button type='primary' size='large' onClick={() => handlePost()}>
                Submit order
              </Button>
              <Text> or </Text>
              <Button type='primary' size='large' onClick={() => handleEdit()}>
                Edit order
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default FormReview
