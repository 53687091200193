import React from 'react'
import FormInput from './form-input-types/FormInput'
import PhoneField from './form-input-types/PhoneField'
import RadioInput from './form-input-types/RadioInput'
import AddressInput from './form-input-types/AddressInput'
import DropdownField from './form-input-types/DropdownField'
// import UploadField from './form-input-types/UploadField'

const FormField = props => {
  const { inputType } = props

  switch (inputType) {
    case 'phone':
      return <PhoneField {...props} />
    case 'radio':
      return <RadioInput {...props} />
    case 'address':
      return <AddressInput {...props} />
    case 'dropdown':
      return <DropdownField {...props} />
    // case 'upload':
    //   return <UploadField {...props} />
    default:
      return <FormInput {...props} />
  }
}

export default FormField
