import React from 'react'
import { Form, Input, Select } from 'antd'
import { phoneNumberValidation } from '../util/validation'

const { Option } = Select

const phonePrefixOptions = ['+61', '+64']

const PhoneField = props => {
  const {
    phonePrefix,
    prefixName,
    numberName,
    label,
    labelAlign = 'left',
    disabled
  } = props

  return (
    <Form.Item
      name={numberName}
      label={label}
      labelAlign={labelAlign}
      rules={phoneNumberValidation}
    >
      <Input
        disabled={disabled}
        addonBefore={
          <Form.Item name={prefixName} noStyle>
            <Select defaultValue={phonePrefixOptions[0]} disabled={disabled}>
              {phonePrefixOptions.map(option => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        }
      />
    </Form.Item>
  )
}

export default PhoneField
