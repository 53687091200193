import React, { useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table
} from 'antd'
import BACK_NECK from '../assets/FFA-Cambridge1.png'
import SLEEVE_LENGTH from '../assets/FFA-Cambridge2.png'
import FINISHED_INLEG_LENGTHS from '../assets/FFA-Cambridge3.png'
import WAIST from '../assets/FFA-Cambridge4.png'
import SILK_SADDLE from '../assets/FFA-Cambridge5.png'
import SHORTERN_SLEEVE_LENGTH from '../assets/FFA-Ganton1.png'
import CHANGE_CUFF from '../assets/FFA-Ganton2.png'
import ADD_REMOVE_POCKET from '../assets/FFA-Ganton3.png'
import { isGantonTemplate } from '../util'

const ChangeCuff = props => {
  const { index, dataIndex, modalData, setModalData } = props
  const handleClick = changeCuffName => () => {
    setModalData(pre => {
      return {
        ...pre,
        changeCuff: pre?.changeCuff === changeCuffName ? '' : changeCuffName
      }
    })
  }
  return (
    <>
      <Form.Item name={[index, dataIndex, 'changeCuff']}>
        <Checkbox
          checked={modalData?.changeCuff === 'Button Cuff'}
          onClick={handleClick('Button Cuff')}
        >
          Button Cuff
        </Checkbox>
      </Form.Item>
      <Form.Item name={[index, dataIndex, 'changeCuff']}>
        <Checkbox
          checked={modalData?.changeCuff === 'French Cuff'}
          onClick={handleClick('French Cuff')}
        >
          French Cuff
        </Checkbox>
      </Form.Item>
      <div style={{ fontSize: '12px' }}>
        Change cuff alteration only available on select styles.
      </div>
    </>
  )
}

const ChangePocket = props => {
  const { index, dataIndex, modalData, setModalData } = props
  const handleClick = addRemovePocket => () => {
    setModalData(pre => {
      return {
        ...pre,
        addRemovePocket:
          pre?.addRemovePocket === addRemovePocket ? '' : addRemovePocket
      }
    })
  }

  return (
    <>
      <Form.Item name={[index, dataIndex, 'addRemovePocket']}>
        <Checkbox
          checked={modalData?.addRemovePocket === 'Add Pocket'}
          onClick={handleClick('Add Pocket')}
        >
          Add Pocket
        </Checkbox>
      </Form.Item>
      <Form.Item name={[index, dataIndex, 'addRemovePocket']}>
        <Checkbox
          checked={modalData?.addRemovePocket === 'Remove Pocket'}
          onClick={handleClick('Remove Pocket')}
        >
          Remove Pocket
        </Checkbox>
      </Form.Item>

      <div style={{ fontSize: '12px' }}>
        Adding pocket alteration only available on select styles.
      </div>
    </>
  )
}

const AddAlterationsFormModal = props => {
  const { index, dataIndex, form } = props

  const [isModalOpen, setIsModalOpen] = useState(false)

  const requestingStore = form.getFieldValue('requestingStore')
  const gantonTemplateFlag = isGantonTemplate(requestingStore)
  const orderItem = form.getFieldValue(['orders', index])
  const alterationFields = Form.useWatch(
    ['orders', index, 'alterationFields'],
    form
  )

  const [modalData, setModalData] = useState(alterationFields)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    form.setFieldValue(['orders', index, dataIndex], modalData)
    form.setFieldValue(
      ['orders', index, 'alterations'],
      `${orderItem?.productCode}-${orderItem?.orderItemId}`
    )
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setModalData(alterationFields)
    setIsModalOpen(false)
  }

  const cambridgeColumns = [
    {
      title: 'Back Neck',
      dataIndex: 'backNeck',
      key: 'backNeck',
      width: '20%'
    },
    {
      title: 'Sleeve Length',
      dataIndex: 'sleeveLength',
      key: 'sleeveLength',
      width: '20%'
    },
    {
      title: 'Finished In-leg Lengths',
      dataIndex: 'finishedInlegLengths',
      key: 'finishedInlegLengths',
      width: '20%'
    },
    {
      title: 'Waist',
      dataIndex: 'waist',
      key: 'waist',
      width: '20%'
    },
    {
      title: 'Silk Saddle',
      dataIndex: 'silkSaddle',
      key: 'silkSaddle',
      width: '20%'
    }
  ]

  const jacketDisabled =
    modalData?.finishedInlegLengths?.left ||
    alterationFields?.finishedInlegLengths?.left ||
    modalData?.finishedInlegLengths?.right ||
    alterationFields?.finishedInlegLengths?.right ||
    Math.ceil(Math.abs(modalData?.waist)) ||
    Math.ceil(Math.abs(alterationFields?.waist)) ||
    modalData?.silkSaddle ||
    alterationFields?.silkSaddle
  const trouserDisabled =
    Math.ceil(Math.abs(modalData?.backNeck)) ||
    Math.ceil(Math.abs(alterationFields?.backNeck)) ||
    modalData?.sleeveLength?.left ||
    alterationFields?.sleeveLength?.left ||
    modalData?.sleeveLength?.right ||
    alterationFields?.sleeveLength?.right

  const cambridgeDataSource = [
    {
      key: 1,
      backNeck: (
        <img
          src={BACK_NECK}
          alt=''
          style={{ maxWidth: '10rem', height: 'auto' }}
        />
      ),
      sleeveLength: (
        <img
          src={SLEEVE_LENGTH}
          alt=''
          style={{ maxWidth: '10rem', height: 'auto' }}
        />
      ),
      finishedInlegLengths: (
        <img
          src={FINISHED_INLEG_LENGTHS}
          alt=''
          style={{ maxWidth: '10rem', height: 'auto' }}
        />
      ),
      waist: (
        <img src={WAIST} alt='' style={{ maxWidth: '10rem', height: 'auto' }} />
      ),
      silkSaddle: (
        <img
          src={SILK_SADDLE}
          alt=''
          style={{ maxWidth: '10rem', height: 'auto' }}
        />
      )
    },
    {
      key: 2,
      backNeck: (
        <Form.Item name={[index, dataIndex, 'backNeck']}>
          <Space>
            <Select
              style={{ width: '5rem' }}
              disabled={jacketDisabled}
              value={modalData?.backNeck || alterationFields?.backNeck}
              onChange={value => {
                setModalData(pre => ({ ...pre, backNeck: value }))
              }}
            >
              <Select.Option value='0.00'>0.00</Select.Option>
              <Select.Option value='-0.5'>-0.5</Select.Option>
              <Select.Option value='-1.0'>-1.0</Select.Option>
            </Select>
            <span>cm</span>
          </Space>
        </Form.Item>
      ),
      sleeveLength: (
        <>
          <Form.Item name={[index, dataIndex, 'sleeveLength', 'left']}>
            <Space>
              <InputNumber
                disabled={jacketDisabled}
                min={0}
                value={
                  modalData?.sleeveLength?.left ||
                  alterationFields?.sleeveLength?.left ||
                  ''
                }
                onChange={value => {
                  setModalData(pre => ({
                    ...pre,
                    sleeveLength: { ...pre?.sleeveLength, left: value }
                  }))
                }}
              />
              <div style={{ width: '10rem' }}>cm Left Sleeve Measurement</div>
            </Space>
          </Form.Item>
          <Form.Item name={[index, dataIndex, 'sleeveLength', 'right']}>
            <Space>
              <InputNumber
                disabled={jacketDisabled}
                min={0}
                value={
                  modalData?.sleeveLength?.right ||
                  alterationFields?.sleeveLength?.right ||
                  ''
                }
                onChange={value => {
                  setModalData(pre => ({
                    ...pre,
                    sleeveLength: { ...pre?.sleeveLength, right: value }
                  }))
                }}
              />
              <div style={{ width: '10rem' }}>cm Right Sleeve Measurement</div>
            </Space>
          </Form.Item>
          <div style={{ fontSize: '12px' }}>Maximum added length +4cm.</div>
          <div style={{ fontSize: '12px' }}>Measure from shoulder to cuf.</div>
        </>
      ),
      finishedInlegLengths: (
        <>
          <Form.Item name={[index, dataIndex, 'finishedInlegLengths', 'left']}>
            <Space>
              <InputNumber
                disabled={trouserDisabled}
                min={0}
                value={
                  modalData?.finishedInlegLengths?.left ||
                  alterationFields?.finishedInlegLengths?.left ||
                  ''
                }
                onChange={value => {
                  setModalData(pre => ({
                    ...pre,
                    finishedInlegLengths: {
                      ...pre?.finishedInlegLengths,
                      left: value
                    }
                  }))
                }}
              />
              <div style={{ width: '10rem' }}>cm Left In-leg Measurement</div>
            </Space>
          </Form.Item>
          <Form.Item name={[index, dataIndex, 'finishedInlegLengths', 'right']}>
            <Space>
              <InputNumber
                disabled={trouserDisabled}
                min={0}
                value={
                  modalData?.finishedInlegLengths?.right ||
                  alterationFields?.finishedInlegLengths?.right ||
                  ''
                }
                onChange={value => {
                  setModalData(pre => ({
                    ...pre,
                    finishedInlegLengths: {
                      ...pre?.finishedInlegLengths,
                      right: value
                    }
                  }))
                }}
              />
              <div style={{ width: '10rem' }}>cm Right In-leg Measurement</div>
            </Space>
          </Form.Item>
          <div style={{ fontSize: '12px' }}>Maximum length +4cm (88cm).</div>
          <div style={{ fontSize: '12px' }}>Measure from top to bottom.</div>
        </>
      ),
      waist: (
        <>
          <Form.Item name={[index, dataIndex, 'waist']}>
            <Space>
              <Select
                disabled={trouserDisabled}
                style={{ width: '5rem' }}
                value={modalData?.waist || alterationFields?.waist}
                onChange={value => {
                  setModalData(pre => ({ ...pre, waist: value }))
                }}
              >
                <Select.Option value='+4.00'>+4.00</Select.Option>
                <Select.Option value='+3.00'>+3.00</Select.Option>
                <Select.Option value='+2.00'>+2.00</Select.Option>
                <Select.Option value='+1.00'>+1.00</Select.Option>
                <Select.Option value='0.00'>0.00</Select.Option>
                <Select.Option value='-1.00'>-1.00</Select.Option>
                <Select.Option value='-2.00'>-2.00</Select.Option>
                <Select.Option value='-3.00'>-3.00</Select.Option>
                <Select.Option value='-4.00'>-4.00</Select.Option>
              </Select>
              <span>cm</span>
            </Space>
          </Form.Item>
          <div style={{ fontSize: '12px' }}>
            *Waist = ticket/soft tag waist size
          </div>
        </>
      ),
      silkSaddle: (
        <Form.Item name={[index, dataIndex, 'silkSaddle']}>
          <Checkbox
            style={{ width: '9rem' }}
            disabled={trouserDisabled}
            checked={modalData?.silkSaddle}
            onChange={e => {
              setModalData(pre => ({ ...pre, silkSaddle: e.target.checked }))
            }}
          >
            Add silk saddle
          </Checkbox>
        </Form.Item>
      )
    }
  ]
  const gantonColumns = [
    {
      title: 'Shorten Sleeve Length',
      dataIndex: 'shortenSleeveLength',
      key: 'shortenSleeveLength',
      width: '40%'
    },
    {
      title: 'Change Cuff',
      dataIndex: 'changeCuff',
      key: 'changeCuff',
      width: '30%'
    },
    {
      title: 'Add / Remove Pocket',
      dataIndex: 'addRemovePocket',
      key: 'addRemovePocket',
      width: '30%'
    }
  ]
  const gantonDataSource = [
    {
      key: 1,
      shortenSleeveLength: (
        <img
          src={SHORTERN_SLEEVE_LENGTH}
          alt=''
          style={{ maxWidth: '15rem', height: 'auto' }}
        />
      ),
      changeCuff: (
        <img
          src={CHANGE_CUFF}
          alt=''
          style={{ maxWidth: '15rem', height: 'auto' }}
        />
      ),
      addRemovePocket: (
        <img
          src={ADD_REMOVE_POCKET}
          alt=''
          style={{ maxWidth: '15rem', height: 'auto' }}
        />
      )
    },
    {
      key: 2,
      shortenSleeveLength: (
        <>
          <Row>
            <Col span={12}>
              <Form.Item
                name={[index, dataIndex, 'shortenSleeveLength', 'left']}
              >
                <Space>
                  <Select
                    style={{ width: '5rem' }}
                    value={
                      modalData?.shortenSleeveLength?.left ||
                      alterationFields?.shortenSleeveLength?.left
                    }
                    onChange={value => {
                      setModalData(pre => ({
                        ...pre,
                        shortenSleeveLength: {
                          ...pre?.shortenSleeveLength,
                          left: value
                        }
                      }))
                    }}
                  >
                    <Select.Option value='0.00'>0.00</Select.Option>
                    <Select.Option value='-1.0'>-1.0</Select.Option>
                    <Select.Option value='-2.0'>-2.0</Select.Option>
                  </Select>
                  <span>cm Left</span>
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[
                  index,
                  dataIndex,
                  'shortenSleeveLength',
                  'leftMeasurement'
                ]}
              >
                <Space>
                  <InputNumber
                    min={0}
                    value={
                      modalData?.shortenSleeveLength?.leftMeasurement ||
                      alterationFields?.shortenSleeveLength?.leftMeasurement
                    }
                    onChange={value =>
                      setModalData(pre => ({
                        ...pre,
                        shortenSleeveLength: {
                          ...pre?.shortenSleeveLength,
                          leftMeasurement: value
                        }
                      }))
                    }
                  />
                  <span>cm Finished Measurement</span>
                </Space>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name={[index, dataIndex, 'shortenSleeveLength', 'right']}
              >
                <Space>
                  <Select
                    style={{ width: '5rem' }}
                    value={
                      modalData?.shortenSleeveLength?.right ||
                      alterationFields?.shortenSleeveLength?.right
                    }
                    onChange={value =>
                      setModalData(pre => ({
                        ...pre,
                        shortenSleeveLength: {
                          ...pre?.shortenSleeveLength,
                          right: value
                        }
                      }))
                    }
                  >
                    <Select.Option value='0.00'>0.00</Select.Option>
                    <Select.Option value='-1.0'>-1.0</Select.Option>
                    <Select.Option value='-2.0'>-2.0</Select.Option>
                  </Select>
                  <span>cm Right</span>
                </Space>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={[
                  index,
                  dataIndex,
                  'shortenSleeveLength',
                  'rightMeasurement'
                ]}
              >
                <Space>
                  <InputNumber
                    min={0}
                    value={
                      modalData?.shortenSleeveLength?.rightMeasurement ||
                      alterationFields?.shortenSleeveLength?.rightMeasurement
                    }
                    onChange={value =>
                      setModalData(pre => ({
                        ...pre,
                        shortenSleeveLength: {
                          ...pre?.shortenSleeveLength,
                          rightMeasurement: value
                        }
                      }))
                    }
                  />
                  <span>cm Finished Measurement</span>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
      changeCuff: (
        <ChangeCuff
          modalData={modalData}
          index={index}
          dataIndex={dataIndex}
          setModalData={setModalData}
        />
      ),
      addRemovePocket: (
        <ChangePocket
          modalData={modalData}
          index={index}
          dataIndex={dataIndex}
          setModalData={setModalData}
        />
      )
    }
  ]

  return (
    <>
      <Button onClick={showModal}>Add</Button>
      <Modal
        width={2000}
        maskClosable={false}
        title={`Product Code: ${
          orderItem?.productCode?.toUpperCase() || ''
        }, Size: ${orderItem?.size || ''}, Colour: ${orderItem?.colour || ''}`}
        okText='Confirm'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Table
          columns={gantonTemplateFlag ? gantonColumns : cambridgeColumns}
          dataSource={
            gantonTemplateFlag ? gantonDataSource : cambridgeDataSource
          }
          pagination={false}
          bordered
        />
        <br />
        {gantonTemplateFlag && (
          <Row>
            <Col span={12}>
              <Form.Item
                label='Neck Size'
                labelCol={{ span: 4 }} // control label width
                wrapperCol={{ span: 10 }} // control input width
                name={[index, dataIndex, 'neckSize']}
              >
                <Space>
                  <InputNumber
                    min={0}
                    value={modalData?.neckSize || alterationFields?.neckSize}
                    onChange={value =>
                      setModalData(pre => ({ ...pre, neckSize: value }))
                    }
                  />
                  <span>cm</span>
                </Space>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label='Sleeve Length'
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 10 }}
                name={[index, dataIndex, 'sleeveLength']}
              >
                <Select
                  value={
                    modalData?.sleeveLength || alterationFields?.sleeveLength
                  }
                  onChange={value =>
                    setModalData(pre => ({ ...pre, sleeveLength: value }))
                  }
                >
                  <Select.Option value='X-Short'>X-Short</Select.Option>
                  <Select.Option value='Short'>Short</Select.Option>
                  <Select.Option value='Regular'>Regular</Select.Option>
                  <Select.Option value='Long'>Long</Select.Option>
                  <Select.Option value='X-Long'>X-Long</Select.Option>
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item
                label='Size'
                name={[index, dataIndex, 'size']}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 10 }}
              >
                <Select
                  value={modalData?.size || alterationFields?.size}
                  onChange={value =>
                    setModalData(pre => ({ ...pre, size: value }))
                  }
                >
                  <Select.Option value=''></Select.Option>
                  <Select.Option value='X-Small'>X-Small</Select.Option>
                  <Select.Option value='Small'>Small</Select.Option>
                  <Select.Option value='Medium'>Medium</Select.Option>
                  <Select.Option value='Large'>Large</Select.Option>
                  <Select.Option value='X-Large'>X-Large</Select.Option>
                  <Select.Option value='2X-Large'>2X-Large</Select.Option>
                  <Select.Option value='3X-Large'>3X-Large</Select.Option>
                  <Select.Option value='4X-Large'>4X-Large</Select.Option>
                  <Select.Option value='6X-Large'>6X-Large</Select.Option>
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
        )}
      </Modal>
    </>
  )
}

export default AddAlterationsFormModal
