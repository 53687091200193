import React from 'react'
import { Form, Select } from 'antd'

const { Option } = Select
const DropdownField = props => {
  const {
    name,
    label,
    labelAlign = 'left',
    validation,
    values,
    disabled
  } = props

  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign={labelAlign}
      rules={validation}
    >
      <Select
        disabled={disabled}
        allowClear
        showSearch
        filterOption={(inputValue, option) =>
          option.children.toLowerCase().includes(inputValue.toLowerCase())
        }
      >
        {values.map((value, index) => {
          return (
            <Option key={index} value={value.code}>
              {value.store}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default DropdownField
