import React, { useState } from 'react'
import { Form, Input, Row, Col, Typography, Select } from 'antd'

const { Option } = Select
const { Text } = Typography

const countryOptions = ['Australia', 'New Zealand']

const AddressInput = props => {
  const { name, label, labelAlign = 'left', validation, disabled } = props

  const currentCountry =
    Form.useFormInstance()?.getFieldValue('address')?.country
  const [countryValue, setCountryValue] = useState(currentCountry)

  return (
    <>
      <Row>
        <Col span={24}>
          <Form.Item
            label={
              <label>
                <Row>
                  <Text>{label}</Text>
                </Row>
                <Row style={{ position: 'absolute', bottom: '-15px' }}>
                  <Text className='ant-form-item-explain ant-form-item-explain-connected'>
                    Cannot be a PO box
                  </Text>
                </Row>
              </label>
            }
            wrapperCol={{ span: 0 }}
            name={[name, 'street']}
            labelAlign={labelAlign}
            help='Street Address Line 1'
            rules={validation}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}></Col>
        <Col span={12}>
          <Form.Item
            wrapperCol={{ span: 0 }}
            name={[name, 'street2']}
            help='Street Address Line 2'
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}></Col>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                wrapperCol={{ span: 0 }}
                name={[name, 'city']}
                help='City'
                rules={validation}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                wrapperCol={{ span: 0 }}
                name={[name, 'state']}
                help='State/Province'
                rules={countryValue === 'New Zealand' ? null : validation}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={12}></Col>
        <Col span={12}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                wrapperCol={{ span: 0 }}
                name={[name, 'zip']}
                help='Postal/Zip Code'
                rules={validation}
              >
                <Input disabled={disabled} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                wrapperCol={{ span: 0 }}
                name={[name, 'country']}
                help='Country'
                rules={validation}
              >
                <Select
                  onChange={value => setCountryValue(value)}
                  disabled={disabled}
                >
                  {countryOptions.map(country => {
                    return (
                      <Option key={country} value={country}>
                        {country}
                      </Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default AddressInput
