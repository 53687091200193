import { v4 as uuidv4 } from 'uuid'

export const initialValues = {
  customerName: '',
  customerEmail: '',
  phonePrefix: '+61',
  phoneNumber: '',
  customerRef: '',
  delivery: 'The requesting store',
  address: {
    street: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: 'Australia'
  },
  storeToDeliver: '',
  orders: [
    {
      addNew: true,
      orderItemId: uuidv4(),
      productCode: '',
      size: '',
      colour: '',
      fabricCode: '',
      quantity: 1,
      alterationFields: {},
      alterations: ''
    }
  ],
  requestingStore: '',
  staffName: ''
}
