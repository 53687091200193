export const getParams = () => {
  return new URLSearchParams(window.location.search).get('resubmission') || ''
}

export const isGantonTemplate = code => {
  const gantonCodes = [
    '1RET824',
    '1RET835',
    '1RET825',
    '1RET819',
    '1RET843',
    '1RET846'
  ]
  return gantonCodes.includes(code)
}

export const isObjectEmpty = obj => {
  return Object.values(obj).every(value => {
    if (typeof value === 'object' && value !== null) {
      return isObjectEmpty(value)
    }
    return value === undefined || value === null || value === ''
  })
}

export const removeOrderErrorAfterSubmit = formData => {
  const orders = formData.orders.map(order => {
    delete order.error
    return order
  })
  return { ...formData, orders }
}
